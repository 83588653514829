import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import Hero from '../../../components/heroSubpage'
import Cta from '../../../components/cta'
import ColumnAlternatingButton from '../../../components/columnAlternatingButton'
import CardIcons from '../../../components/cardIcons.js'
import FadeInWhenVisible from '../../../components/fadeInWhenVisible.js'

const SupportIntel = ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}          
          type="root"
        />
      </div>

      <section className="bg-white">
        <MDBContainer>
          <MDBRow>
            <MDBCol>
              <div className="mt-3 richtext divlink" dangerouslySetInnerHTML={{ __html: post.html }} />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <section className="bg-white-grey-gradient">
        <FadeInWhenVisible>
          <MDBContainer>
            <h3 className="font-alt text-left font-w-700 letter-spacing-1 mb-3 title-xs-medium title-medium">
              {post.frontmatter.quality.title}
            </h3>
            <h4 class="font-w-400 text-medium">
              {post.frontmatter.quality.subtitle}
            </h4>
            {post.frontmatter.quality.feature && (
              <MDBRow className="pt-5">
                {post.frontmatter.quality.feature.map((features, index) => (
                  <CardIcons
                    key={index}
                    collg="6"
                    colmd="6"
                    title={features.title}
                    subtitle={features.subtitle}
                    image={features.image.childImageSharp.gatsbyImageData}
                    alt={features.alttext}
                  />
                ))}
              </MDBRow>
            )}
          </MDBContainer>
        </FadeInWhenVisible>
      </section>

      <section className="bg-white">
        <FadeInWhenVisible>
          <MDBContainer>
            <ColumnAlternatingButton
              textCol={'5'}
              imageCol={'7'}
              title={post.frontmatter.partnering.title}
              subtitle={post.frontmatter.partnering.subtitle}
              description={post.frontmatter.partnering.description}
              image={post.frontmatter.partnering.image.childImageSharp.gatsbyImageData}
              alt={post.frontmatter.partnering.alttext}
              placement={post.frontmatter.partnering.placement}
              titleClass="font-alt font-w-700 letter-spacing-1 mb-4 title-xs-medium title-medium"
              link={post.frontmatter.partnering.link}
              linktext={post.frontmatter.partnering.linktext}
            />
          </MDBContainer>
        </FadeInWhenVisible>
      </section>

      <Cta
        ctatitle={post.frontmatter.cta.title}
        ctadescription={post.frontmatter.cta.description}
        ctalink={post.frontmatter.cta.link}
        ctalinktext={post.frontmatter.cta.linktext}
      />
    </Layout>
  )
}
export default SupportIntel

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}   
      canonical={location.pathname} 
    />   
  )
}

export const query = graphql`
  query {
    markdownRemark(
      frontmatter: {
        name: { eq: "intel-data-center-solutions-premier-support-partner" }
      }
    ) {
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        alttext
        ctatext
        ctaslug        
        quality {
          title
          subtitle
          feature {
            title
            subtitle
            alttext
            image {
              childImageSharp {
                gatsbyImageData(width: 70, height: 70, quality: 90) 
              }
            }
          }
        }
        partnering {
          title
          description
          placement
          alttext
          image {
            childImageSharp {
              gatsbyImageData(quality: 90) 
            }
          }
        }
        cta {
          title
          description
          linktext
          link
        }
      }
      html
    }
  }
`
